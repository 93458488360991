import { SortOrder } from "../@types";
import { API_URL } from "../utils/constants";
import { IEmployee } from "./employeeService";

export enum AuditLogAction {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export enum AuditLogEntityName {
  INVESTOR = "INVESTOR",
  DOCUMENT = "DOCUMENT",
  BANK_DETAIL = "BANK_DETAIL",
  CONSTRUCTION_UPDATE = "CONSTRUCTION_UPDATE",
  CONSTRUCTION_UPDATE_PICTURE = "CONSTRUCTION_UPDATE_PICTURE",
  ADDRESS = "ADDRESS",
  PARKING_SPACE = "PARKING_SPACE",
  EMPLOYEE = "EMPLOYEE",
  PROPERTY = "PROPERTY",
  TENANT = "TENANT",
  COURSE_MODULE = "COURSE_MODULE",
  COURSE_MODULE_ITEM = "COURSE_MODULE_ITEM",
  LEASE = "LEASE",
  LEASE_PAYMENT = "LEASE_PAYMENT",
  LEASE_OCCUPANT = "LEASE_OCCUPANT",
  UNIT = "UNIT",
  UNIT_OWNER = "UNIT_OWNER",
  UNIT_PICTURE = "UNIT_PICTURE",
}

export interface IAuditLog {
  audit_log_id: number;
  entity_id: number;
  old_value: any;
  new_value: any;
  employee: IEmployee;
  entity_name: AuditLogEntityName;
  action: AuditLogAction;
  log_date: string;
  log_time: string;
}

export enum AuditLogSortBy {
  DATE = "date",
  TIME = "time",
}

export const fetchAuditLogs = async (url: string): Promise<IAuditLog[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.audit_logs;
};

export const constructAuditLogsUrl = (
  page?: number,
  pageSize?: number,
  action?: string,
  start_date?: string,
  end_date?: string,
  time?: string,
  entity_name?: string,
  entity_id?: string,
  employee_id?: string,
  sortBy?: AuditLogSortBy,
  sortOrder?: SortOrder
): string => {
  let queryParams = new URLSearchParams();

  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }
  if (action !== undefined && action.length > 0) {
    queryParams.append("action", action);
  }
  if (start_date !== undefined && start_date.length > 0) {
    queryParams.append("start_date", start_date);
  }
  if (end_date !== undefined && end_date.length > 0) {
    queryParams.append("end_date", end_date);
  }
  if (time !== undefined && time.length > 0) {
    queryParams.append("time", time);
  }
  if (entity_name !== undefined && entity_name.length > 0) {
    queryParams.append("entity_name", entity_name);
  }
  if (entity_id !== undefined && entity_id.length > 0) {
    queryParams.append("entity_id", entity_id);
  }
  if (employee_id !== undefined && employee_id.length > 0) {
    queryParams.append("employee_id", employee_id);
  }
  if (sortBy !== undefined) {
    queryParams.append("sortBy", sortBy);
  }
  if (sortOrder !== undefined) {
    queryParams.append("sortOrder", sortOrder);
  }

  return `${API_URL}/audit-logs?${queryParams.toString()}`;
};
