import { UnitStatus } from '../../services/unitService';
import './unit-status-badge.css';

import React from 'react';

interface UnitStatusBadgeProps {
    className?: string;
    status: UnitStatus;
}

const UnitStatusBadge: React.FC<UnitStatusBadgeProps> = ({ className, status }) => {
    return (
        <span className={`unit-status-badge ${status.toLowerCase()} ${className ?? ""}`}>
            {status}
        </span>
    );
};

export default UnitStatusBadge;
