import './property-card.css';

import React from 'react';
import { SPACES_ENDPOINT } from '../../utils/constants';
import TheIvyImage from "../../assets/images/the-ivy.jpeg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IoLocationOutline } from 'react-icons/io5';

interface PropertyCardProps {
    imageSrc?: string;
    title: string;
    location: string;
    locationUrl: string;
    onClick: () => void;
}

const PropertyCard: React.FC<PropertyCardProps> = ({
    imageSrc,
    title,
    location,
    locationUrl,
    onClick,
}) => {
    return (
        <div className={`property-card h-100`} onClick={onClick}>
            <LazyLoadImage
                className="property-image"
                wrapperClassName="property-image-wrapper"
                placeholderSrc='https://placehold.co/600x400'
                src={imageSrc ? `${SPACES_ENDPOINT}${imageSrc}` : TheIvyImage}
                alt={title}
                effect="opacity"
                width="100%"
                height="150px"
                draggable={false}
            />
            <div className="property-content">
                <h3 className="property-title">{title}</h3>
                <div className="property-location-wrapper">
                    <IoLocationOutline size={20} className="property-map-icon" />
                    <a
                        className="property-location ms-1"
                        href={locationUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}>
                        {location}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PropertyCard;
