// UnitDetailPage.tsx
import "./unit-detail-page.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Lottie from "lottie-react";
import DOMPurify from 'dompurify';
import _ from 'lodash';
import useAlertStore from "../../stores/alertStore";
import CustomButton from "../../components/custom-button/custom-button";
import { getFormattedDate } from "../../utils/helpers";
import { UploadedFile } from "../../components/file-upload/file-upload";
import { useTranslation } from "react-i18next";
import { IDocument } from "../../services/documentService";
import useEmployeeStore from "../../stores/employeeStore";
import useUnitDetailStore from "../../stores/unitDetailStore";
import { IUnit, deleteUnit, fetchUnit, updateUnit, uploadUnitDocuments, uploadUnitPictures } from "../../services/unitService";
import UnitDetails from "./components/unit-details/unit-details";
import UnitGeneralSection from "./components/unit-general-section/unit-general-section";
import UnitUtilitySection from "./components/unit-utility-section/unit-utility-section";
import OwnerDetails from "./components/owner-details/owner-details";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import DeleteAnim from "../../assets/anims/delete-anim.json";
import UnitPicturesSection from "./components/unit-pictures-section/unit-pictures-section";
import TenantDetails from "./components/tenant-details/tenant-details";
import DeleteConfirmationModal from "../../modals/delete-confirmation-modal/delete-confirmation-modal";
import useEditPermission from "../../hooks/useEditPermission";
import ParkingSpaceSection from "./components/parking-space-section/parking-space-section";
import usePageTitle from "../../hooks/usePageTitle";
import UnitConditionSection from "./components/unit-condition-section/unit-condition-section";
import FileUploadSection from "../../components/file-upload-section/file-upload-section";
import { EmployeeRole } from "../../services/employeeService";

const UnitDetailPage = () => {
    let { unitId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        unit,
        isLoading,
        isDeleting,
        setUnit,
        setIsLoading,
        setIsDeleting,
        addUnitOwner,
        addParkingSpace,
        removeParkingSpace
    } = useUnitDetailStore();
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);

    const hasEditPermission = useEditPermission(employeeRoleId);
    const hasDeletePermission = useEditPermission(employeeRoleId, [EmployeeRole.ADMINISTRATOR]);

    // States
    const [unitOriginal, setUnitOriginal] = useState<IUnit | null>(null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [files, setFiles] = useState<UploadedFile[]>([]);
    const [pictures, setPictures] = useState<UploadedFile[]>([]);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);

    useEffect(() => {
        if (unitId) {
            const loadUnit = async () => {
                try {
                    setIsLoading(true);
                    const unit = await fetchUnit(Number(unitId));
                    setUnit(unit);
                    setUnitOriginal(unit);
                } catch (error) {
                    console.log(`Error while fetching unit with id ${unitId}:`, error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsLoading(false);
                }
            }
            loadUnit();
        }
    }, [unitId, setIsLoading, setUnit, setUnitOriginal, showAlert]);

    // Set page title
    usePageTitle(t("pages.unit_detail.page_title", { name: unit ? unit.unit_number : "" }));

    const onClickUpdateUnit = useCallback(async () => {
        if (unit) {
            try {
                setIsUpdating(true);
                const updatedUnit = await updateUnit(unit);
                setUnit({
                    ...unit,
                    unit_number: updatedUnit.unit_number,
                    floor: updatedUnit.floor,
                    square_feet: updatedUnit.square_feet,
                    bedroom: updatedUnit.bedroom,
                    bathroom: updatedUnit.bathroom,
                    dewa: updatedUnit.dewa,
                    gas_no: updatedUnit.gas_no,
                    municipality_no: updatedUnit.municipality_no,
                    under_renovation: updatedUnit.under_renovation,
                    under_dispute: updatedUnit.under_dispute,
                    pm_contract_signed: updatedUnit.pm_contract_signed,
                    furnished: updatedUnit.furnished,
                });

                // Upload new documents
                if (files.length > 0) {
                    const uploadedDocuments = await uploadUnitDocuments(
                        unit.unit_id,
                        files.map(file => file.file),
                        files.map(file => file.internal_document ? "true" : "false")
                    );
                    const updatedDocuments = unit.documents;
                    updatedDocuments.push(...uploadedDocuments);
                    setUnit({
                        ...unit,
                        documents: updatedDocuments
                    });
                    setFiles([]);
                }

                // Upload new pictures
                if (pictures.length > 0) {
                    const uploadedPictures = await uploadUnitPictures(unit.unit_id, pictures.map(file => file.file));
                    const updatedPictures = unit.pictures;
                    updatedPictures.push(...uploadedPictures);
                    setUnit({
                        ...unit,
                        pictures: updatedPictures
                    });
                    setPictures([]);
                }

                setUnitOriginal(unit);

                showAlert("success", t("pages.unit_detail.save_success_message"));
            } catch (error) {
                console.log(`Error while updating unit (id: ${unitId}):`, error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsUpdating(false);
            }
        }
    }, [unit, files, pictures, unitId, setUnit, showAlert, t]);

    const onClickUpdatePmContractSigned = useCallback(async () => {
        if (unit) {
            try {
                setIsUpdating(true);
                const updatedUnit = await updateUnit({ ...unit, pm_contract_signed: true });
                setUnit(updatedUnit);
                setUnitOriginal(updatedUnit);
            } catch (error) {
                console.log(`Error while updating unit pm contract signed (id: ${unitId}):`, error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsUpdating(false);
            }
        }
    }, [unit, unitId, setUnit, showAlert]);

    const onDeleteUnitDocument = useCallback(async (document: IDocument) => {
        if (unit) {
            const documents = unit.documents.filter(file => file.document_id !== document.document_id);
            setUnit({ ...unit, documents });
            setUnitOriginal({ ...unit, documents });
        }
    }, [unit, setUnit, setUnitOriginal]);

    const onPictureDeleted = useCallback(async (picture_id: number) => {
        const updatePictures = (unit: IUnit): IUnit => {
            const updatedPictures = unit.pictures.filter(picture => picture.picture_id !== picture_id);
            return { ...unit, pictures: updatedPictures };
        };

        useUnitDetailStore.setState(prevState => {
            if (!prevState.unit) return prevState;
            const updatedUnit = updatePictures(prevState.unit);
            return { unit: updatedUnit };
        });

        setUnitOriginal(prevUnitOriginal => {
            if (!prevUnitOriginal) return prevUnitOriginal;
            return updatePictures(prevUnitOriginal);
        });
    }, [setUnitOriginal]);

    const onDeleteUnit = useCallback(async () => {
        try {
            setIsDeleteModalVisible(false);
            setIsDeleting(true);

            const unitDeleted = await deleteUnit(Number(unitId));
            if (unitDeleted) {
                navigate("/units");
                showAlert("success", t("pages.unit_detail.delete_success_message"));
            }
        } catch (error) {
            console.log('Error while deleting unit:', error);
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setIsDeleting(false);
        }
    }, [unitId, t, setIsDeleting, showAlert, navigate]);

    const dataHasBeenChanged = useMemo(() => {
        return !_.isEqual(unit, unitOriginal) || files.length > 0 || pictures.length > 0;
    }, [unit, unitOriginal, files, pictures]);

    return (
        <div className="no-select unit-detail-page">
            {isLoading || isDeleting ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
                    <Lottie animationData={isLoading ? LoadingAnim : DeleteAnim} loop={true} style={{ height: isLoading ? 100 : 150 }} />
                </div>
            ) : (
                unit && (
                    <>
                        {/* SECTION TITLE */}
                        <div className="d-flex justify-content-between align-items-center">
                            {unit && (
                                <>
                                    <div>
                                        <div className="d-flex">
                                            <h4 className="headline m-0 me-2">Unit {unit.unit_number} | {unit.property.property_name}</h4>
                                            {/*<InvestorStatusBadge status={unit.status.status_name} />*/}
                                        </div>
                                        <p style={{ margin: 0, fontSize: 14 }}>{t("pages.unit_detail.subtitle", { date: getFormattedDate(unit.created_at) })} | ID: {unit.unit_id}</p>
                                    </div>

                                    {/* DESKTOP BUTTON */}
                                    {hasEditPermission && unitOriginal?.pm_contract_signed && (
                                        <div className="d-none d-md-flex">
                                            {!isUpdating && hasDeletePermission && (
                                                <CustomButton
                                                    className="d-none d-md-block me-3"
                                                    title={t("buttons.delete")}
                                                    color="red"
                                                    isLoading={isDeleting}
                                                    onClick={() => setIsDeleteModalVisible(true)}
                                                />
                                            )}
                                            <CustomButton
                                                className="d-none d-md-block"
                                                title={t("buttons.save")}
                                                isLoading={isUpdating}
                                                disabled={!dataHasBeenChanged}
                                                onClick={onClickUpdateUnit}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        {/* UNIT OVERVIEW */}
                        {unitOriginal?.pm_contract_signed ? (
                            <>
                                <div className="row mt-3 gy-3">
                                    <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                                        <div className="row gy-4">
                                            <div className="col-12">
                                                <UnitDetails unit={unit} leasesAmount={0} />
                                            </div>
                                            <div className="col-12">
                                                <OwnerDetails
                                                    employeeRoleId={employeeRoleId}
                                                    propertyId={unit.property.property_id}
                                                    unitId={unit.unit_id}
                                                    owners={unit.owners}
                                                    onUnitOwnerAdded={addUnitOwner}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <TenantDetails
                                                    employeeRoleId={employeeRoleId}
                                                    propertyId={unit.property.property_id}
                                                    unitId={unit.unit_id}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 col-lg-7 col-xl-8">
                                        <div className="row gy-4">

                                            {/* UNIT STATES SECTION */}
                                            <div className="col-12">
                                                <UnitConditionSection
                                                    employeeRoleId={employeeRoleId}
                                                    unit={unit}
                                                    onUpdateUnit={setUnit}
                                                />
                                            </div>

                                            {/* UITILITY OVERVIEW */}
                                            <div className="col-12">
                                                <UnitUtilitySection
                                                    employeeRoleId={employeeRoleId}
                                                    unit={unit}
                                                    onUpdateUnit={setUnit}
                                                />
                                            </div>

                                            {/* GENERAL INFORMATION */}
                                            <div className="col-12">
                                                <UnitGeneralSection
                                                    employeeRoleId={employeeRoleId}
                                                    unit={unit}
                                                    onUpdateUnit={setUnit}
                                                />
                                            </div>

                                            {/* UNIT PARKING SPACE SECTION */}
                                            <ParkingSpaceSection
                                                property_id={unit.property.property_id}
                                                unit_id={unit.unit_id}
                                                parkingSpaces={unit.parking_spaces}
                                                onAddParkingSpace={addParkingSpace}
                                                onDeleteParkingSpace={removeParkingSpace}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* PICTURES SECTION */}
                                <UnitPicturesSection
                                    employeeRoleId={employeeRoleId}
                                    unitId={unit.unit_id}
                                    pictures={unit.pictures}
                                    newPictures={pictures}
                                    onPictureDeleted={onPictureDeleted}
                                    onUpdatePictures={setPictures}
                                />

                                {/* DOCUMENT SECTION */}
                                <div className="col-12">
                                    <FileUploadSection
                                        colStyle="col-12 col-md-6"
                                        fileUploadId="unit-upload-other-documents"
                                        title={t("components.document_section.available_documents_title", { number: unit.documents.length })}
                                        subtitle={t("components.document_section.provide_documents_subtitle")}
                                        documents={unit.documents}
                                        uploadedFiles={files}
                                        onDeleteDocument={onDeleteUnitDocument}
                                        onUpdateUploadedFiles={setFiles}
                                        hasEditPermission={hasEditPermission}
                                        isDefaultExpanded
                                        allowInternalDocument
                                    />
                                </div>

                                {/* MOBILE BUTTON */}
                                {hasEditPermission && (
                                    <div className="d-md-none">
                                        <CustomButton
                                            className="mt-4 w-100"
                                            title={t("buttons.save")}
                                            isLoading={isUpdating}
                                            disabled={!dataHasBeenChanged}
                                            onClick={onClickUpdateUnit}
                                        />
                                        {!isUpdating && hasDeletePermission && (
                                            <CustomButton
                                                className="mt-3 w-100"
                                                title={t("buttons.delete")}
                                                color="red"
                                                isLoading={isDeleting}
                                                onClick={() => setIsDeleteModalVisible(true)}
                                            />
                                        )}
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="pm-contract-sign-section">
                                    {isUpdating ? (
                                        <div className="loading-indicator d-flex justify-content-center align-items-center">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="pm-contract-sign-message" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t("pages.unit_detail.pm_contract_not_signed_message")) }} />
                                            {/* PM CONTRACT SIGNED SWITCH */}
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="pm-contract-signed-switch"
                                                    checked={false}
                                                    onChange={onClickUpdatePmContractSigned}
                                                />
                                                <label className="form-check-label" htmlFor="pm-contract-signed-switch">
                                                    {t("labels.pm_contract_signed")}
                                                </label>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </>
                )
            )}

            {/* DELETE CONFIRMATION MODAL */}
            {
                isDeleteModalVisible && hasEditPermission && (
                    <DeleteConfirmationModal
                        title={t("pages.unit_detail.delete_modal.title")}
                        message={t("pages.unit_detail.delete_modal.message")}
                        onClose={() => setIsDeleteModalVisible(false)}
                        onDelete={onDeleteUnit}
                    />
                )
            }
        </div >
    );
};

export default UnitDetailPage;
