// InvestorDetailPage.tsx
import "./investor-detail-page.css";

import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useInvestorDetailStore from "../../stores/investorDetailStore";
import { IInvestor, deleteInvestor, fetchInvestor, updateInvestor, uploadInvestorDocuments } from "../../services/investorService";
import useAlertStore from "../../stores/alertStore";
import CustomButton from "../../components/custom-button/custom-button";
import { getFormattedDate } from "../../utils/helpers";
import InvestorStatusBadge from "../../components/investor-status-badge/investor-status-badge";
import { UploadedFile } from "../../components/file-upload/file-upload";
import AddressForm from "../../components/address-form/address-form";
import { updateAddress } from "../../services/addressService";
import { useTranslation } from "react-i18next";
import { DocumentType, IDocument } from "../../services/documentService";
import useEmployeeStore from "../../stores/employeeStore";
import { EmployeeRole } from "../../services/employeeService";
import ExpandableSection from "../../components/expandable-section/expandable-section";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import DeleteAnim from "../../assets/anims/delete-anim.json";
import InvestorDocumentSection from "./components/investor-document-section";
import InvestorGeneralSection from "./components/investor-general-section/investor-general-section";
import useEditPermission from "../../hooks/useEditPermission";
import DeleteConfirmationModal from "../../modals/delete-confirmation-modal/delete-confirmation-modal";
import { IUnitOwner } from "../../services/unitOwnerService";
import usePageTitle from "../../hooks/usePageTitle";
import OwnedUnitsTable from "./components/owned-units-table/owned-units-table";
import InvestorBankDetailsSection, { BankDetailsForm } from "./components/investor-bank-details-section/investor-bank-details-section";
import { IBankDetail, createBankDetail, updateBankDetail, uploadBankDetailDocuments } from "../../services/bankDetailService";
import { SPACES_ENDPOINT } from "../../utils/constants";
import DefaultAvatar from "../../assets/images/default-avatar.webp";

const InvestorDetailPage = () => {
    let { investorId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        investor,
        isLoading,
        isDeleting,
        setInvestor,
        setIsLoading,
        setIsDeleting,
    } = useInvestorDetailStore();
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);

    const hasEditPermission = useEditPermission(employeeRoleId);

    // States
    const [investorOriginal, setInvestorOriginal] = useState<IInvestor | null>(null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [bankDetailsForm, setBankDetailsForm] = useState<BankDetailsForm | null>(null);
    const [bankDetailsOriginal, setBankDetailsOriginal] = useState<BankDetailsForm | null>(null);

    // Error States
    const [firstnameError, setFirstnameError] = useState<string>("");
    const [lastnameError, setLastnameError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [phoneNumberError, setPhoneNumberError] = useState<string>("");

    // Uploading Files
    const [passportFiles, setPassportFiles] = useState<UploadedFile[]>([]);
    const [proofOfAddressFile, setProofOfAddressFile] = useState<UploadedFile | null>(null);
    const [powerOfAttorneyFile, setPowerOfAttorneyFile] = useState<UploadedFile | null>(null);
    const [files, setFiles] = useState<UploadedFile[]>([]);

    useEffect(() => {
        if (investorId) {
            const loadInvestor = async () => {
                try {
                    setIsLoading(true);
                    const investor = await fetchInvestor(Number(investorId));
                    setInvestor(investor);
                    setInvestorOriginal(investor);
                    const firstBankDetail: BankDetailsForm = investor.bank_details[0] ? {
                        iban: investor.bank_details[0].iban,
                        swift: investor.bank_details[0].swift,
                        bank_name: investor.bank_details[0].bank_name,
                        account_holder_name: investor.bank_details[0].account_holder_name,
                        currency: investor.bank_details[0].currency,
                        branch_number: investor.bank_details[0].branch_number ?? null,
                        account_number: investor.bank_details[0].account_number ?? null
                    } : {
                        iban: '',
                        swift: '',
                        bank_name: '',
                        account_holder_name: '',
                        currency: 'EUR',
                        branch_number: null,
                        account_number: null
                    };
                    setBankDetailsOriginal(firstBankDetail);
                    setBankDetailsForm(firstBankDetail);
                } catch (error) {
                    console.log(`Error while fetching investor with id ${investorId}:`, error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsLoading(false);
                }
            }
            loadInvestor();
        }
    }, [investorId, setInvestor, setIsLoading, showAlert]);

    // Set page title
    usePageTitle(t("pages.investor_detail.page_title", { name: investor ? `${investor.first_name} ${investor.last_name}` : "" }));

    const onClickUpdateInvestor = useCallback(async () => {
        if (investor) {
            let isValid = true;

            // Check if the firstname is not empty
            if (!investor.first_name.trim()) {
                setFirstnameError(t("invalid_input.firstname_empty"));
                isValid = false;
            }

            // Check if the lastname is not empty
            if (!investor.last_name.trim()) {
                setLastnameError(t("invalid_input.lastname_empty"));
                isValid = false;
            }

            // Basic email validation
            if (!investor.email.trim()) {
                setEmailError(t("invalid_input.email_empty"));
                isValid = false;
            } else if (!/^\S+@\S+\.\S+$/.test(investor.email.trim())) {
                setEmailError(t("invalid_input.email_invalid"));
                isValid = false;
            }

            // Check if the phone number is not empty
            if (!investor.phone_number?.trim()) {
                setPhoneNumberError(t("invalid_input.phone_number_empty"));
                isValid = false;
            }

            if (isValid) {
                try {
                    setIsUpdating(true);

                    // Update investor
                    if (!_.isEqual(investor, investorOriginal)) {
                        const updatedInvestor = await updateInvestor(investor);
                        setInvestor({
                            ...investor,
                            email: updatedInvestor.email,
                            phone_number: updatedInvestor.phone_number,
                            first_name: updatedInvestor.first_name,
                            last_name: updatedInvestor.last_name
                        });
                    }

                    // Update investor address only when there are changes
                    if (!_.isEqual(investor.address, investorOriginal?.address)) {
                        const updatedAddress = await updateAddress(investor.address);
                        setInvestor({ ...investor, address: updatedAddress });
                        setInvestorOriginal({ ...investor, address: updatedAddress });
                    }

                    // Update investor bank details
                    if (bankDetailsForm) {
                        let bankDetails: IBankDetail | null = null;
                        if (investor.bank_details.length > 0) {
                            bankDetails = await updateBankDetail(investor.bank_details[0].details_id, bankDetailsForm);
                            setInvestor({ ...investor, bank_details: [bankDetails] });
                            setInvestorOriginal({ ...investor, bank_details: [bankDetails] });
                            const bankDetailForm = {
                                iban: bankDetails.iban,
                                swift: bankDetails.swift,
                                bank_name: bankDetails.bank_name,
                                account_holder_name: bankDetails.account_holder_name,
                                currency: bankDetails.currency,
                                branch_number: bankDetails.branch_number ?? null,
                                account_number: bankDetails.account_number ?? null
                            }
                            setBankDetailsOriginal(bankDetailForm);
                            setBankDetailsForm(bankDetailForm);
                        } else {
                            if (bankDetailsForm.iban.length > 0 && bankDetailsForm.account_holder_name.length > 0 && bankDetailsForm.swift.length > 0 && bankDetailsForm.bank_name.length > 0) {
                                bankDetails = await createBankDetail(investor.investor_id, bankDetailsForm);
                                setInvestor({ ...investor, bank_details: [bankDetails] });
                                setInvestorOriginal({ ...investor, bank_details: [bankDetails] });
                                const bankDetailForm = {
                                    iban: bankDetails.iban,
                                    swift: bankDetails.swift,
                                    bank_name: bankDetails.bank_name,
                                    account_holder_name: bankDetails.account_holder_name,
                                    currency: bankDetails.currency,
                                    branch_number: bankDetails.branch_number ?? null,
                                    account_number: bankDetails.account_number ?? null
                                }
                                setBankDetailsOriginal(bankDetailForm);
                                setBankDetailsForm(bankDetailForm);
                            }
                        }

                        // Upload power of attorney file
                        if (bankDetails && powerOfAttorneyFile) {
                            const newDocuments = await uploadBankDetailDocuments(
                                bankDetails.details_id,
                                [powerOfAttorneyFile.file],
                                DocumentType.POWER_OF_ATTORNEY,
                                [powerOfAttorneyFile].map(file => file.valid_until ? file.valid_until.toISOString() : null)
                            );
                            setPowerOfAttorneyFile(null);

                            // Find the bank detail and update its documents array
                            const updatedBankDetails = investor.bank_details.map(bankDetail => {
                                if (bankDetail.details_id === bankDetails!.details_id) {
                                    return {
                                        ...bankDetail,
                                        documents: [...bankDetail.documents, ...newDocuments]
                                    };
                                }
                                return bankDetail;
                            });

                            setInvestor({ ...investor, bank_details: updatedBankDetails });
                            setInvestorOriginal({ ...investor, bank_details: updatedBankDetails });
                        }
                    }

                    // Upload new passport documents
                    if (passportFiles.length > 0) {
                        const newDocuments = await uploadInvestorDocuments(
                            investor.investor_id,
                            passportFiles.map(file => file.file),
                            DocumentType.PASSPORT,
                            passportFiles.map(file => file.valid_until ? file.valid_until.toISOString() : null)
                        );
                        setPassportFiles([]);

                        const newDocumentFiles = investor.documents;
                        newDocumentFiles.push(...newDocuments);
                        setInvestor({ ...investor, documents: newDocumentFiles });
                        setInvestorOriginal({ ...investor, documents: newDocumentFiles });
                    }

                    // Upload new proof of address file
                    if (proofOfAddressFile) {
                        const newDocuments = await uploadInvestorDocuments(
                            investor.investor_id,
                            [proofOfAddressFile.file],
                            DocumentType.PROOF_OF_ADDRESS,
                            [proofOfAddressFile].map(file => file.valid_until ? file.valid_until.toISOString() : null)
                        );
                        setProofOfAddressFile(null);

                        const newDocumentFiles = investor.documents;
                        newDocumentFiles.push(...newDocuments);
                        setInvestor({ ...investor, documents: newDocumentFiles });
                        setInvestorOriginal({ ...investor, documents: newDocumentFiles });
                    }

                    // Upload other documents
                    if (files.length > 0) {
                        const uploadedDocuments = await uploadInvestorDocuments(investor.investor_id, files.map(file => file.file), null, [], files.map(file => file.internal_document ? "true" : "false"));
                        setFiles([]);

                        const updatedDocuments = investor.documents;
                        updatedDocuments.push(...uploadedDocuments);
                        setInvestor({
                            ...investor,
                            documents: updatedDocuments
                        });
                        setInvestorOriginal({ ...investor, documents: updatedDocuments });
                    }

                    showAlert("success", t("pages.investor_detail.investor_saved_success_message"));
                } catch (error) {
                    console.log('Error while updating investor: ', error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsUpdating(false);
                }
            }
        }
    }, [investor, investorOriginal, files, proofOfAddressFile, passportFiles, powerOfAttorneyFile, bankDetailsForm, setInvestor, setInvestorOriginal, showAlert, t]);

    const onProofOfAddressFileSelected = (selectedFile: UploadedFile[]) => {
        setProofOfAddressFile(selectedFile[0]);
    };

    const onPowerOfAttorneyFileSelected = (selectedFile: UploadedFile[]) => {
        setPowerOfAttorneyFile(selectedFile[0]);
    };

    const onDeleteInvestorDocument = useCallback(async (document: IDocument) => {
        if (investor) {
            const documents = investor.documents.filter(file => file.document_id !== document.document_id);
            setInvestor({ ...investor, documents });
            setInvestorOriginal({ ...investor, documents });
        }
    }, [investor, setInvestor]);

    const onDeleteBankDetailsDocument = useCallback(async (document: IDocument) => {
        if (investor && investor.bank_details.length > 0) {
            const updatedBankDetails = investor.bank_details.map((bankDetail, index) =>
                index === 0
                    ? { ...bankDetail, documents: bankDetail.documents.filter(file => file.document_id !== document.document_id) }
                    : bankDetail
            );

            setInvestor({ ...investor, bank_details: updatedBankDetails });
            setInvestorOriginal({ ...investor, bank_details: updatedBankDetails });
        }
    }, [investor, setInvestor, setInvestorOriginal]);

    const onDeleteInvestor = useCallback(async () => {
        try {
            setIsDeleteModalVisible(false);
            setIsDeleting(true);

            const investorDeleted = await deleteInvestor(Number(investorId));
            if (investorDeleted) {
                navigate("/investors/");
                showAlert("success", t("pages.investor_detail.delete_success_message"));
            }
        } catch (error) {
            console.log('Error while deleting investor:', error);
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setIsDeleting(false);
        }
    }, [investorId]);

    const dataHasBeenChanged = useMemo(() => {
        return !_.isEqual(investor, investorOriginal) || !_.isEqual(bankDetailsForm, bankDetailsOriginal) || passportFiles.length > 0 || proofOfAddressFile || files.length > 0 || powerOfAttorneyFile;
    }, [investor, investorOriginal, passportFiles, proofOfAddressFile, powerOfAttorneyFile, files, bankDetailsForm, bankDetailsOriginal]);

    const onClearError = (field: String) => {
        if (field === "firstname") {
            setFirstnameError("");
        }
        else if (field === "lastname") {
            setLastnameError("");
        }
        else if (field === "email") {
            setEmailError("");
        }
        else if (field === "phone_number") {
            setPhoneNumberError("");
        }
    }

    const onBackDetailsDeleted = useCallback(() => {
        if (investor) {
            setInvestor({ ...investor, bank_details: [] });
            setInvestorOriginal({ ...investor, bank_details: [] });

            const detailFormResetted = {
                iban: '',
                swift: '',
                bank_name: '',
                account_holder_name: '',
                currency: 'EUR',
                branch_number: null,
                account_number: null
            };
            setBankDetailsForm(detailFormResetted);
            setBankDetailsOriginal(detailFormResetted);
        }
    }, [investor]);

    return (
        <div className="no-select">
            {isLoading || isDeleting ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
                    <Lottie animationData={isLoading ? LoadingAnim : DeleteAnim} loop={true} style={{ height: isLoading ? 100 : 150 }} />
                </div>
            ) : (
                investor && (
                    <>
                        {/* SECTION TITLE */}
                        <div className="d-flex justify-content-between align-items-center">
                            {investor && (
                                <>
                                    <div className="d-flex justify-content-items align-items-center">
                                        <div className="d-flex">
                                            <img style={{ width: 50, height: 50, borderRadius: 25 }} src={investor.avatar && investor.avatar.length > 0 ? `${SPACES_ENDPOINT}${investor.avatar}` : DefaultAvatar} alt="Avatar" />
                                            <div className="ms-3">
                                                <div className="d-flex">
                                                    <h4 className="m-0 me-2">{investor.first_name} {investor.last_name}</h4>
                                                    <InvestorStatusBadge status={investor.status.status_name} />
                                                </div>
                                                <p style={{ margin: 0, fontSize: 14 }}>{t("pages.investor_detail.subtitle", { date: getFormattedDate(investor.created_at) })} | ID: {investor.investor_id}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* DESKTOP BUTTON */}
                                    {hasEditPermission && (
                                        <div className="d-none d-md-flex">
                                            {!isUpdating && (
                                                <CustomButton
                                                    className="d-none d-md-block me-3"
                                                    title={t("buttons.delete")}
                                                    color="red"
                                                    isLoading={isDeleting}
                                                    onClick={() => setIsDeleteModalVisible(true)}
                                                />
                                            )}
                                            {!isDeleting && (
                                                <CustomButton
                                                    className="d-none d-md-block"
                                                    title={t("buttons.save")}
                                                    isLoading={isUpdating}
                                                    disabled={!dataHasBeenChanged}
                                                    onClick={onClickUpdateInvestor}
                                                />
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        {/* INVESTOR OVERVIEW */}
                        {investor && (
                            <div className="row gy-4 mt-3">

                                {/* INVESTOR OWNED UNITS */}
                                <div className="col-12">
                                    <OwnedUnitsTable
                                        investorId={investor.investor_id}
                                        unitOwners={investor.owned_units}
                                        onUnitOwnerAdded={(unitOwner: IUnitOwner) => {
                                            const currentUnitOwners = investor.owned_units;
                                            currentUnitOwners.push(unitOwner);
                                            setInvestor({ ...investor, owned_units: currentUnitOwners })
                                        }}
                                    />
                                </div>

                                {/* INVESTOR GENERAL INFO */}
                                <div className="col-12 col-xl-6">
                                    <InvestorGeneralSection
                                        employeeRoleId={employeeRoleId}
                                        investor={investor}
                                        firstnameError={firstnameError}
                                        lastnameError={lastnameError}
                                        emailError={emailError}
                                        phoneNumberError={phoneNumberError}
                                        onUpdateInvestor={setInvestor}
                                        onClearError={onClearError}
                                    />
                                </div>

                                {/* INVESTOR ADDRESS INFO */}
                                <div className="col-12 col-xl-6">
                                    <ExpandableSection
                                        className="investor-address-section"
                                        title={t("pages.investor_detail.address_title")}
                                        isHeadlineLarge
                                        isDefaultExpanded
                                    >
                                        <div className="white-card">
                                            <AddressForm
                                                address={investor.address}
                                                onAddressChanged={(address) => {
                                                    setInvestor({ ...investor, address });
                                                }}
                                                disabled={employeeRoleId !== EmployeeRole.ADMINISTRATOR}
                                            />
                                        </div>
                                    </ExpandableSection>
                                </div>

                                {/* INVESTOR BANK DETAILS INFO */}
                                <div className="col-12">
                                    <InvestorBankDetailsSection
                                        details_id={investor.bank_details.length > 0 ? investor.bank_details[0].details_id : null}
                                        bank_details={investor.bank_details.length > 0 ? investor.bank_details[0] : null}
                                        powerOfAttorney={investor.bank_details.length > 0 ? investor.bank_details[0].documents : []}
                                        powerOfAttorneyFile={powerOfAttorneyFile}
                                        onUpdateBankDetailsForm={setBankDetailsForm}
                                        onBankDetailsDeleted={onBackDetailsDeleted}
                                        onUpdatePowerOfAttorneyFiles={onPowerOfAttorneyFileSelected}
                                        onDeleteDocument={onDeleteBankDetailsDocument}
                                    />
                                </div>
                            </div>
                        )}

                        {/* DOCUMENT SECTION */}
                        <InvestorDocumentSection
                            employeeRoleId={employeeRoleId}
                            files={files}
                            documents={investor.documents}
                            onDeleteInvestorDocument={onDeleteInvestorDocument}
                            onUpdatePassportFiles={(files: UploadedFile[]) => setPassportFiles(files)}
                            onUpdateInvestorFiles={setFiles}
                            onProofOfAddressFileSelected={onProofOfAddressFileSelected}
                            proofOfAddressFile={proofOfAddressFile}
                            passportFiles={passportFiles}
                            removeProofOfAddressFile={() => setProofOfAddressFile(null)}
                        />

                        {/* MOBILE BUTTON */}
                        {hasEditPermission && (
                            <div className="d-md-none">
                                {!isDeleting && (
                                    <CustomButton
                                        className="mt-4 w-100"
                                        title={t("buttons.save")}
                                        isLoading={isUpdating}
                                        disabled={!dataHasBeenChanged}
                                        onClick={onClickUpdateInvestor}
                                    />
                                )}
                                {!isUpdating && (
                                    <CustomButton
                                        className="mt-3 w-100"
                                        title={t("buttons.delete")}
                                        color="red"
                                        isLoading={isDeleting}
                                        onClick={() => setIsDeleteModalVisible(true)}
                                    />
                                )}
                            </div>
                        )}
                    </>
                )
            )}

            {/* DELETE CONFIRMATION MODAL */}
            {isDeleteModalVisible && hasEditPermission && (
                <DeleteConfirmationModal
                    title={t("pages.investor_detail.delete_modal.title")}
                    message={t("pages.investor_detail.delete_modal.message")}
                    onClose={() => setIsDeleteModalVisible(false)}
                    onDelete={onDeleteInvestor}
                />
            )}
        </div>
    );
};

export default InvestorDetailPage;
