import { SidebarSelectFieldOptions } from "../modals/sidebar/components/sidebar-select-field/sidebar-select-field";

const isProduction = process.env.REACT_APP_ENV === "production";
const isTest = process.env.REACT_APP_ENV === "test";

export const API_URL = isProduction
  ? "https://employee-api.danielgarofoli.com"
  : isTest
  ? "https://employee-api-test.danielgarofoli.com"
  : "http://localhost:8585";
export const SPACES_ENDPOINT = isProduction
  ? "https://dg-home-prod.fra1.digitaloceanspaces.com/"
  : "https://dg-home-test.fra1.digitaloceanspaces.com/";

export const INVESTOR_STATUS_OPTIONS: SidebarSelectFieldOptions[] = [
  { label: "Aktiv", value: "1" },
  { label: "Inaktiv", value: "2" },
  { label: "Entwurf", value: "3" },
];
