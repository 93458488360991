import "./property-detail-page.css";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useAlertStore from "../../stores/alertStore";
import PaymentDetails from "./components/payment-details/payment-details";
import { IPropertyDashboard, fetchPropertyDashboard } from "../../services/propertyService";
import PropertyDetails from "./components/property-details/property-details";
import UpcomingUnits from "./components/upcoming-units/upcoming-units";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import { SPACES_ENDPOINT } from "../../utils/constants";
import AvailableUnits from "./components/available-units/available-units";
import usePageTitle from "../../hooks/usePageTitle";
import { useTranslation } from "react-i18next";
import PaymentsTable from "./components/payments-table/payments-table";
import { LeasePaymentFilter } from "../../services/leasePaymentService";
import useEmployeeStore from "../../stores/employeeStore";
import { EmployeeRole } from "../../services/employeeService";

// Role-Based UI logic extracted into a separate function
const renderFinanceManagerSection = (propertyDashboard: IPropertyDashboard) => (
    <>
        <div className="col-12">
            <PaymentsTable propertyId={propertyDashboard.property.property_id} filter={LeasePaymentFilter.OVERDUE} />
        </div>
        <div className="col-12">
            <PaymentsTable propertyId={propertyDashboard.property.property_id} filter={LeasePaymentFilter.UPCOMING} />
        </div>
    </>
);

const renderNonFinanceManagerSection = (propertyDashboard: IPropertyDashboard) => (
    <>
        <div className="col-12">
            <UpcomingUnits leases={propertyDashboard.availableSoonLeases} />
        </div>
        <div className="col-12">
            <AvailableUnits propertyId={propertyDashboard.property.property_id} />
        </div>
        <div className="col-12 col-xl-6">
            <PaymentsTable propertyId={propertyDashboard.property.property_id} filter={LeasePaymentFilter.OVERDUE} />
        </div>
        <div className="col-12 col-xl-6">
            <PaymentsTable propertyId={propertyDashboard.property.property_id} filter={LeasePaymentFilter.UPCOMING} />
        </div>
    </>
);

const PropertyDetailPage = () => {
    const { propertyId } = useParams();
    const location = useLocation();
    const { t } = useTranslation();

    // Params
    const searchParams = new URLSearchParams(location.search);
    const propertyName = decodeURIComponent(searchParams.get("propertyName") || "");

    // Set page title
    usePageTitle(t("pages.property_detail.page_title", { name: propertyName }));

    // Stores
    const { employee } = useEmployeeStore();
    const showAlert = useAlertStore((state) => state.showAlert);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [propertyDashboard, setPropertyDashboard] = useState<IPropertyDashboard | null>(null);

    useEffect(() => {
        if (propertyId) {
            const loadPropertyDashboard = async () => {
                try {
                    setIsLoading(true);
                    const propertyDashboard = await fetchPropertyDashboard(Number(propertyId));
                    setPropertyDashboard(propertyDashboard);
                } catch (error) {
                    console.log(`Error while fetching dashboard data for property with id ${propertyId}:`, error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsLoading(false);
                }
            };
            loadPropertyDashboard();
        }
    }, [propertyId, showAlert]);

    // Main render logic
    return (
        <div className="no-select">
            {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "80vh" }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                propertyDashboard && (
                    <div className="row gy-4">
                        {/* Property Details */}
                        <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                            <PropertyDetails property={{
                                property_id: propertyDashboard.property.property_id,
                                image: `${SPACES_ENDPOINT}${propertyDashboard.property.property_image}`,
                                name: propertyDashboard.property.property_name,
                                email: propertyDashboard.property.email,
                                phone_number: propertyDashboard.property.phone_number,
                                plot_no: propertyDashboard.property.plot_no,
                                type: "Residential",
                                tenants: propertyDashboard.rentedUnits,
                                residents: propertyDashboard.totalOccupants,
                                units: propertyDashboard.totalUnits,
                                vacant: propertyDashboard.totalUnits - propertyDashboard.rentedUnits,
                                upcoming: propertyDashboard.totalAvailableSoonUnits,
                                leasePercentage: propertyDashboard.totalUnits > 0
                                    ? (propertyDashboard.rentedUnits / propertyDashboard.totalUnits) * 100
                                    : 0
                            }} />
                        </div>

                        {/* Payment Details and Role-based Sections */}
                        <div className="col-12 col-md-6 col-lg-7 col-xl-8">
                            <div className="row gy-4">
                                <div className="col-12">
                                    <PaymentDetails payments={{
                                        rent: propertyDashboard.rentThisMonth,
                                        additionalServices: propertyDashboard.petDepositThisMonth,
                                        deposit: propertyDashboard.depositThisMonth
                                    }} />
                                </div>

                                {/* Render sections based on role */}
                                {employee?.role_id === EmployeeRole.FINANCE_MANAGER
                                    ? renderFinanceManagerSection(propertyDashboard)
                                    : renderNonFinanceManagerSection(propertyDashboard)
                                }
                            </div>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default PropertyDetailPage;
