import { API_URL } from "../utils/constants";
import { IDocument } from "./documentService";
import { TFunction } from "i18next";

export enum PaymentMethod {
  CASH = "cash",
  CHEQUE = "cheque",
  BANK_TRANSFER = "bank_transfer",
}

export const formatPaymentMethod = (method: PaymentMethod, t: TFunction) => {
  switch (method) {
    case PaymentMethod.CHEQUE:
      return t("payment_method.cheque");
    case PaymentMethod.CASH:
      return t("payment_method.cash");
    case PaymentMethod.BANK_TRANSFER:
      return t("payment_method.bank_transfer");
    default:
      return "-";
  }
};

export enum PaymentPurpose {
  RENT = "rent",
  SECURITY_DEPOSIT = "security_deposit",
  PET_DEPOSIT = "pet_deposit",
  ADMIN_FEE = "admin_fee",
}

export const formatPaymentPurpose = (purpose: PaymentPurpose, t: TFunction) => {
  switch (purpose) {
    case PaymentPurpose.RENT:
      return t("payment_purpose.rent");
    case PaymentPurpose.SECURITY_DEPOSIT:
      return t("payment_purpose.security_deposit");
    case PaymentPurpose.PET_DEPOSIT:
      return t("payment_purpose.pet_deposit");
    case PaymentPurpose.ADMIN_FEE:
      return t("payment_purpose.admin_fee");
    default:
      return "-";
  }
};

export enum LeasePaymentFilter {
  OVERDUE = "overdue",
  UPCOMING = "upcoming",
}

export interface ILeasePayment {
  payment_id: number;
  lease_id: number;
  due_date: string;
  amount: number;
  cheque_no: string | null;
  bank_name: string | null;
  remark: string | null;
  method: PaymentMethod;
  purpose: PaymentPurpose;
  payment_date: string | null;
  document: IDocument | null;
  created_at: string;
  updated_at: string;
}

export const fetchLeasePayments = async (
  url: string
): Promise<{
  lease_payments: ILeasePayment[];
  totalCount: number;
  currentPageCount: number;
}> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return {
    lease_payments: data.lease_payments,
    totalCount: data.totalCount,
    currentPageCount: data.currentPageCount,
  };
};

export const constructLeasePaymentsUrl = (
  propertyId?: number,
  page?: number,
  pageSize?: number,
  filter?: LeasePaymentFilter
): string => {
  let queryParams = new URLSearchParams();

  if (propertyId !== undefined) {
    queryParams.append("propertyId", propertyId.toString());
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }
  if (filter !== undefined) {
    queryParams.append("filter", filter);
  }

  return `${API_URL}/lease-payments?${queryParams.toString()}`;
};

export interface ICreateLeasePayment {
  lease_id: number;
  due_date: Date;
  amount: number;
  cheque_no: string | null;
  bank_name: string | null;
  remark: string | null;
  method: PaymentMethod;
  purpose: PaymentPurpose;
}

export const createLeasePayments = async (
  payments: ICreateLeasePayment[]
): Promise<ILeasePayment[]> => {
  const formattedPayments = payments.map((payment) => ({
    lease_id: payment.lease_id,
    due_date: payment.due_date,
    amount: payment.amount,
    cheque_no: payment.cheque_no,
    bank_name: payment.bank_name,
    remark: payment.remark,
    method: payment.method,
    purpose: payment.purpose,
  }));

  const response = await fetch(`${API_URL}/lease-payments`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formattedPayments),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const updateLeasePayment = async (
  payment_id: number,
  payment: ICreateLeasePayment
): Promise<ILeasePayment> => {
  const formattedPayment = {
    lease_id: payment.lease_id,
    due_date: payment.due_date,
    amount: payment.amount,
    cheque_no: payment.cheque_no,
    bank_name: payment.bank_name,
    remark: payment.remark,
    method: payment.method,
    purpose: payment.purpose,
  };

  const response = await fetch(`${API_URL}/lease-payments/${payment_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formattedPayment),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

export const uploadLeasePaymentDocument = async (
  payment_id: number,
  file: File
): Promise<IDocument> => {
  const formData = new FormData();
  formData.append(`file`, file);

  const response = await fetch(
    `${API_URL}/lease-payments/${payment_id}/document`,
    {
      method: "POST",
      credentials: "include",
      body: formData,
    }
  );

  const data = await response.json();
  if (response.status === 201) return data.document;
  else throw new Error(data.message);
};

export const deleteLeasePayment = async (
  payment_id: number
): Promise<boolean> => {
  const response = await fetch(`${API_URL}/lease-payments/${payment_id}`, {
    method: "DELETE",
    credentials: "include",
  });
  return response.status === 200;
};
