import './sidebar.css';

import React, { useEffect, useState } from 'react';
import {
    IoAccessibility,
    IoAccessibilityOutline,
    IoArchive,
    IoArchiveOutline,
    IoBusiness,
    IoBusinessOutline,
    IoChevronDown,
    IoConstruct,
    IoConstructOutline,
    IoDocument,
    IoDocumentOutline,
    IoExitOutline,
    IoGrid,
    IoGridOutline,
    IoHome,
    IoHomeOutline,
    IoPeople,
    IoPeopleOutline,
    IoPersonAdd,
    IoPersonAddOutline,
    IoSchool,
    IoSchoolOutline
} from "react-icons/io5";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconType } from 'react-icons';
import DGLogo from '../../assets/images/dg-logo.png';
import useEmployeeStore from '../../stores/employeeStore';
import { logout } from '../../services/authService';
import useInvestorDetailStore from '../../stores/investorDetailStore';
import useConstructionUpdateStore from '../../stores/constructionUpdateStore';
import { useTranslation } from 'react-i18next';
import { EmployeeRole } from '../../services/employeeService';
import LanguageSelector from '../language-selector/language-selector';

interface MenuItem {
    category: string,
    id: number,
    name: string,
    activeIcon: IconType,
    inactiveIcon: IconType,
    path: string,
    isVisible: boolean;
    isActivated: boolean;
}

interface SidebarProps {
    isSidebarVisible: boolean;
    toggleSidebar: () => void;
}

const isProduction = process.env.REACT_APP_ENV === "production";

const Sidebar: React.FC<SidebarProps> = ({ isSidebarVisible, toggleSidebar }) => {
    const location = useLocation();
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    // Stores
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const resetEmployeeStore = useEmployeeStore(state => state.reset);
    const resetInvestoreDetailStore = useInvestorDetailStore(state => state.reset);
    const resetConstructionUpdateStore = useConstructionUpdateStore(state => state.reset);

    // States
    const [activePath, setActivePath] = useState<string>(location.pathname);
    const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    useEffect(() => {
        setActivePath(location.pathname);

        const selectedItem = menuItems.find(
            item => location.pathname.startsWith(item.path) && item.isVisible
        );

        // If the selected item's category isn't expanded, expand it
        if (selectedItem && !expandedCategories.includes(selectedItem.category)) {
            setExpandedCategories(prevCategories => [...prevCategories, selectedItem.category]);
        }
    }, [location.pathname, menuItems, expandedCategories]);

    useEffect(() => {
        // Update menu items based on employee role
        const updatedMenuItems = [
            // General
            { category: t("sidebar.categories.general"), id: 1, name: t("sidebar.menu_dashboard"), activeIcon: IoGrid, inactiveIcon: IoGridOutline, path: '/dashboard', isVisible: false, isActivated: true },
            { category: t("sidebar.categories.general"), id: 2, name: t("sidebar.menu_employees"), activeIcon: IoPeople, inactiveIcon: IoPeopleOutline, path: '/employees', isVisible: false, isActivated: true },
            { category: t("sidebar.categories.general"), id: 11, name: t("sidebar.menu_audit_logs"), activeIcon: IoArchive, inactiveIcon: IoArchiveOutline, path: '/audit-logs', isVisible: false, isActivated: true },

            // Property Management
            { category: t("sidebar.categories.property_management"), id: 5, name: t("sidebar.menu_properties"), activeIcon: IoBusiness, inactiveIcon: IoBusinessOutline, path: '/properties', isVisible: false, isActivated: true },
            { category: t("sidebar.categories.property_management"), id: 6, name: t("sidebar.menu_units"), activeIcon: IoHome, inactiveIcon: IoHomeOutline, path: '/units', isVisible: false, isActivated: true },
            { category: t("sidebar.categories.property_management"), id: 3, name: t("sidebar.menu_investors"), activeIcon: IoPersonAdd, inactiveIcon: IoPersonAddOutline, path: '/investors', isVisible: false, isActivated: true },
            { category: t("sidebar.categories.property_management"), id: 4, name: t("sidebar.menu_tenants"), activeIcon: IoAccessibility, inactiveIcon: IoAccessibilityOutline, path: '/tenants', isVisible: false, isActivated: true },
            { category: t("sidebar.categories.property_management"), id: 10, name: t("sidebar.menu_leases"), activeIcon: IoDocument, inactiveIcon: IoDocumentOutline, path: '/leases', isVisible: false, isActivated: true },

            // Others
            { category: t("sidebar.categories.others"), id: 7, name: t("sidebar.menu_construction_progress"), activeIcon: IoConstruct, inactiveIcon: IoConstructOutline, path: '/construction-updates', isVisible: false, isActivated: true },
            { category: t("sidebar.categories.others"), id: 8, name: t("sidebar.menu_after_sale_course"), activeIcon: IoSchool, inactiveIcon: IoSchoolOutline, path: '/courses', isVisible: false, isActivated: true },
            // { category: t("sidebar.categories.others"), id: 9, name: t("sidebar.menu_support"), activeIcon: IoChatboxEllipses, inactiveIcon: IoChatboxEllipsesOutline, path: '/support', isVisible: false, isActivated: false },

            // Finance Management
            { category: t("sidebar.categories.finance_management"), id: 12, name: t("sidebar.menu_properties"), activeIcon: IoBusiness, inactiveIcon: IoBusinessOutline, path: '/properties', isVisible: false, isActivated: true },
            { category: t("sidebar.categories.finance_management"), id: 13, name: t("sidebar.menu_leases"), activeIcon: IoDocument, inactiveIcon: IoDocumentOutline, path: '/leases', isVisible: false, isActivated: true },

        ].map(item => {
            if (employeeRoleId === EmployeeRole.ADMINISTRATOR) {
                if (item.id !== 12) { // Employees | Construction Updates | After Sale Course | Audit Logs
                    return { ...item, isVisible: true };
                }
            }
            if (employeeRoleId === EmployeeRole.PROPERTY_MANAGER) {
                if (item.id !== 2 && item.id !== 7 && item.id !== 8 && item.id !== 11 && item.id !== 12) {
                    return { ...item, isVisible: true };
                }
            }
            if (employeeRoleId === EmployeeRole.FINANCE_MANAGER) {
                if (item.id === 12 || item.id === 13) {
                    return { ...item, isVisible: true };
                }
            }
            return item;
        });

        setMenuItems(updatedMenuItems);
    }, [employeeRoleId, t, i18n.language]);

    const onClickLogout = async () => {
        await logout();

        resetEmployeeStore();
        resetInvestoreDetailStore();
        resetConstructionUpdateStore();
    }

    const handleItemClick = (item: MenuItem) => {
        if (!item.isActivated) return;

        if (activePath !== item.path) {
            setActivePath(item.path);
            toggleSidebar();
        } else {
            window.location.reload();
        }
    };

    const toggleCategory = (category: string) => {
        setExpandedCategories(prevCategories =>
            prevCategories.includes(category)
                ? prevCategories.filter(cat => cat !== category)
                : [...prevCategories, category]
        );
    };

    return (
        <div className={`col-lg-2 sidebar ${isSidebarVisible ? 'visible' : ''}`} id="sidebar">
            <div className="sidebar-header">
                <img src={DGLogo} className="dg-logo" role="button" alt="dg-home-logo" onClick={() => navigate("/")} />
                <div className="title-wrapper">
                    <h4 className="sidebar-title">{t("sidebar.title")}</h4>
                    <p className="environment">{isProduction ? "(Production Environment)" : "(Test Environment)"}</p>
                </div>
            </div>
            <div className="divider mt-3" />
            <div className="scrollable-content">
                <ul className="nav flex-column">
                    {menuItems.reduce((acc: JSX.Element[], item, index) => {
                        const isVisible = item.isVisible && item.isActivated;
                        const isActive = location.pathname.startsWith(item.path);
                        const IconComponent = isActive ? item.activeIcon : item.inactiveIcon;
                        const isFirstItemOfCategory = index === 0 || menuItems[index - 1].category !== item.category;
                        const isCategoryExpanded = expandedCategories.includes(item.category);

                        const isCategoryVisible = menuItems.some(
                            menuItem => menuItem.category === item.category && menuItem.isVisible && menuItem.isActivated
                        );

                        if (isVisible) {
                            if (isFirstItemOfCategory && isCategoryVisible) {
                                acc.push(
                                    <div className="category-wrapper" key={item.category}>
                                        <p
                                            className={`sidebar-category ${isCategoryExpanded ? 'expanded' : ''}`}
                                            onClick={() => toggleCategory(item.category)}
                                        >
                                            {item.category}
                                            <span className="category-arrow">
                                                <IoChevronDown />
                                            </span>
                                        </p>
                                    </div>
                                );
                            }

                            if (isCategoryExpanded) {
                                acc.push(
                                    <li
                                        key={item.name}
                                        className={`nav-item ${isActive ? 'active' : ''}`} // Using isActive
                                    >
                                        <Link
                                            to={item.path}
                                            className={`nav-link ${!item.isActivated ? 'deactivated' : ''}`}
                                            onClick={() => handleItemClick(item)}
                                        >
                                            <span className="icon me-2">
                                                <IconComponent size={18} />
                                            </span>
                                            {item.name}
                                        </Link>
                                    </li>
                                );
                            }
                        }

                        return acc;
                    }, [])}
                </ul>
            </div>
            <div className="sidebar-footer mt-auto">
                <li className="nav-item logout-button">
                    <Link
                        to="/login"
                        className="nav-link logout"
                        onClick={onClickLogout}
                    >
                        <span className="icon me-2">
                            <IoExitOutline />
                        </span>
                        {t("sidebar.menu_logout")}
                    </Link>
                </li>
                <div className="divider" />
                <li className="nav-item">
                    <LanguageSelector />
                </li>
            </div>
        </div>
    );

};

export default Sidebar;
