// PropertyDetails.tsx
import './property-details.css';

import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomPhoneInput from '../../../../components/custom-phone-input/custom-phone-input';
import CustomInputField from '../../../../components/custom-input-field/custom-input-field';
import CustomButton from '../../../../components/custom-button/custom-button';
import useEditPermission from '../../../../hooks/useEditPermission';
import useEmployeeStore from '../../../../stores/employeeStore';
import { EmployeeRole } from '../../../../services/employeeService';
import { updateProperty } from '../../../../services/propertyService';
import useAlertStore from '../../../../stores/alertStore';
import ExpandableSection from '../../../../components/expandable-section/expandable-section';

interface IPropertyDetails {
    property_id: number;
    image: string;
    name: string;
    email: string | null;
    phone_number: string | null;
    plot_no: number;
    type: string;
    tenants: number;
    residents: number;
    units: number;
    vacant: number;
    upcoming: number;
    leasePercentage: number;
}

interface Props {
    property: IPropertyDetails;
}

const PropertyDetails: React.FC<Props> = ({ property }) => {
    const { t } = useTranslation();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);

    const hasEditPermission = useEditPermission(employeeRoleId, [EmployeeRole.ADMINISTRATOR]);

    // Stores
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [propertyDetails, setPropertyDetails] = useState<IPropertyDetails>(property);
    const [localPropertyEmail, setLocalPropertyEmail] = useState<string | null>(property.email);
    const [localPropertyPhoneNumber, setLocalPropertyPhoneNumber] = useState<string | null>(property.phone_number);

    const leasePercentage = useMemo(() => {
        return propertyDetails.leasePercentage.toFixed(0);
    }, [propertyDetails]);

    const dataHasBeenChanged = useMemo(() => {
        return !_.isEqual(propertyDetails.email, localPropertyEmail) || !_.isEqual(propertyDetails.phone_number, localPropertyPhoneNumber);
    }, [propertyDetails, localPropertyEmail, localPropertyPhoneNumber]);

    const onClickUpdateProperty = useCallback(async () => {
        try {
            setIsUpdating(true);
            await updateProperty(propertyDetails.property_id, localPropertyEmail, localPropertyPhoneNumber);
            setPropertyDetails({ ...propertyDetails, email: localPropertyEmail, phone_number: localPropertyPhoneNumber });
            showAlert("success", t("pages.property_detail.property_saved_success_message"));
        } catch (error) {
            console.log('Error while updating property: ', error);
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setIsUpdating(false);
        }
    }, [propertyDetails, localPropertyEmail, localPropertyPhoneNumber, t, showAlert, setPropertyDetails, setIsUpdating]);

    return (
        <div className="property-details-card">
            <div className="image-container">
                <img className="image" src={propertyDetails.image} alt={`${propertyDetails.name}`} draggable={false} />
                <span className="badge property-type">{propertyDetails.type}</span>
            </div>
            <div className="property-info">
                <h5 className="property-name">{propertyDetails.name}</h5>
                <p className="property-plot-no">Plot-No: {propertyDetails.plot_no}</p>
                <div className="divider" />
                <div className="stats">
                    <div className="stat-item">{propertyDetails.residents} <span>{t("pages.property_detail.residents_label")}</span></div>
                    <div className="stat-item">{propertyDetails.units} <span>{t("pages.property_detail.units_label")}</span></div>
                    <div className="stat-item">{propertyDetails.vacant} <span>{t("pages.property_detail.vacant_label")}</span></div>
                    <div className="stat-item">{propertyDetails.upcoming} <span>{t("pages.property_detail.upcoming_label")}</span></div>
                </div>
                <div className="progress custom-progress">
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${leasePercentage}%` }}
                        aria-valuenow={parseFloat(leasePercentage)}
                        aria-valuemin={0}
                        aria-valuemax={100}
                    />
                </div>
                <p className="property-leased-progress">{t("pages.property_detail.leased_by", { percentage: leasePercentage })}</p>

                {/* Additional property details */}
                {employeeRoleId !== EmployeeRole.FINANCE_MANAGER && (
                    <div className="contact-details">
                        <ExpandableSection
                            className="mt-4"
                            title="Contact details"
                            isDefaultExpanded
                        >
                            <CustomInputField
                                id="property-email"
                                type="email"
                                label={t("labels.email")}
                                placeholder="demo@project.ae"
                                value={localPropertyEmail ?? ""}
                                onChange={setLocalPropertyEmail}
                                onClear={() => setLocalPropertyEmail("")}
                            />
                            <CustomPhoneInput
                                id="property-phone-number"
                                defaultCountry="DE"
                                value={localPropertyPhoneNumber ?? undefined}
                                onChange={setLocalPropertyPhoneNumber}
                                onClear={() => setLocalPropertyPhoneNumber("")}
                                labelOnTop
                            />
                        </ExpandableSection>

                        {hasEditPermission && (
                            <CustomButton
                                className="mb-4 mt-2 w-100"
                                title={t("buttons.save")}
                                isLoading={isUpdating}
                                disabled={!dataHasBeenChanged}
                                onClick={onClickUpdateProperty}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PropertyDetails;
