import './tenant-table.css';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ITenant, exportTenantCsv } from '../../services/tenantService';
import { censorEmail, censorPhoneNumber } from '../../utils/helpers';
import CustomButton from '../custom-button/custom-button';

interface TenantTableProps {
    tenants: ITenant[];
}

const TenantTable: React.FC<TenantTableProps> = ({ tenants }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // States
    const [privacyMode, setPrivacyMode] = useState<boolean>(true);
    const [selectedTenants, setSelectedTenants] = useState<Set<number>>(new Set());
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCheckboxChange = (tenantId: number) => {
        setSelectedTenants(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(tenantId)) {
                newSelected.delete(tenantId);
            } else {
                newSelected.add(tenantId);
            }
            return newSelected;
        });
    };

    const handleExport = async () => {
        const selected = Array.from(selectedTenants);
        setIsLoading(true);
        try {
            const blob = await exportTenantCsv(selected);

            // Generate current date and time string
            const now = new Date();
            const formattedDate = now.toLocaleDateString('en-GB').split('/').reverse().join('-'); // yyyy-mm-dd
            const formattedTime = now.toLocaleTimeString().replace(/:/g, "-"); // hh-mm-ss    

            const fileName = `tenants_${formattedDate}_${formattedTime}.csv`;

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();

            // Reset selected tenants
            setSelectedTenants(new Set());
        } catch (error) {
            console.error("Error exporting tenants:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const onClickTenant = (tenant: ITenant) => {
        navigate(`/tenants/${tenant.tenant_id}`);
    };

    return (
        <div>
            {selectedTenants.size > 0 && (
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="d-flex align-items-center ms-auto">
                        <span className="me-3">{selectedTenants.size} {t("labels.selected")}</span>
                        <CustomButton
                            title={t("buttons.csv_export")}
                            isLoading={isLoading}
                            onClick={handleExport}
                        />
                    </div>
                </div>
            )}
            <label className="form-switch ms-2 mb-3">
                <input className="form-check-input" type="checkbox" checked={privacyMode} onChange={() => setPrivacyMode(!privacyMode)} />
                <label className="form-check-label ms-2">{t("labels.privacy_mode_switch")}</label>
            </label>
            <div className="tenant-table-responsive">
                <table className="tenant-table">
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedTenants(new Set(tenants.map(t => t.tenant_id)));
                                        } else {
                                            setSelectedTenants(new Set());
                                        }
                                    }}
                                    checked={selectedTenants.size === tenants.length}
                                />
                            </th>
                            <th>{t("tenant_table.name_header")}</th>
                            <th className="d-none d-md-table-cell">{t("tenant_table.email_header")}</th>
                            <th className="d-none d-md-table-cell">{t("tenant_table.phone_number_header")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tenants.map(tenant => (
                            <tr key={tenant.tenant_id} onClick={() => onClickTenant(tenant)}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedTenants.has(tenant.tenant_id)}
                                        onChange={() => handleCheckboxChange(tenant.tenant_id)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </td>
                                <td>{tenant.first_name} {tenant.last_name}</td>
                                <td className="d-none d-md-table-cell">
                                    <span>
                                        {privacyMode ? censorEmail(tenant.email) : tenant.email}
                                    </span>
                                </td>
                                <td className="d-none d-md-table-cell">
                                    {privacyMode ? censorPhoneNumber(tenant.phone_number ?? undefined) : tenant.phone_number ?? "-"}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TenantTable;
